import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import './App.css';
import Header from './component/Header';
import Footer from './component/Footer';
import Banner from './component/Banner';
import About from './component/About';
import Catagory from './component/Catagory';
import Videos from './component/Videos';
import Contact from './component/Contact';
import Privacy from './component/Privacy';
import Gallery from './component/Gallery';
import Conditions from './component/Terms&Conditions';
import Disclaimer from './component/Disclaimer';

// Initialize Google Analytics 4
const measurementId = 'G-9C4JPDCLPJ'; // Replace with your actual Measurement ID
ReactGA.initialize(measurementId);

function App() {
    const location = useLocation();

    useEffect(() => {
        // Track page view on route change
        ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    }, [location]);

    return (
        <div id="root">
            <Header />
            <Routes>
                <Route path="/" element={<><Banner /><Catagory /></>} /> 
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/video" element={<Videos />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/condition" element={<Conditions />} />
                <Route path="/disclaimer" element={<Disclaimer />} />
                <Route path="/gallery" element={<Gallery />} />
            </Routes>
            <Footer />
        </div>
    );
}

function AppWrapper() {
    return (
        <Router>
            <App />
        </Router>
    );
}

export default AppWrapper;
