import React from 'react';
import './About.css'
import photo11 from './assests/photo11.jpeg';
const About = () => {
  return (
    <div>
        <div className='benner'>
            <div className='about-one'>
                <img src={photo11} alt='about-photo' className='about-image'></img>
            </div>
        </div>
      <div className='about'>
        <p className='about-text'>Welcome to our sacred space dedicated to the teachings and legacy of Acharya Rajendraprashad Shastriji, a beacon of wisdom and spiritual enlightenment. Our mission is to share the profound insights and teachings of Shastriji, helping individuals connect with their inner selves and discover the essence of Hindu philosophy.</p>
      </div>
    </div>
  );
};

export default About;