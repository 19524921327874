import React from 'react'
import './Disclaimer.css'
export default function Disclaimer() {
  return (
    <div className='disclaimer-container'>
      <h1 className='disclaimer-text-one'>Disclaimer</h1>
      <p className='disclaimer-text-two'>Content Disclaimer</p>
      <p className='disclaimer-text-one'>The information provided on this website is for general informational purposes only. While we strive to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.</p>
      <p className='disclaimer-text-two'>External Links Disclaimer</p>
      <p className='disclaimer-text-one'>This website may contain links to external websites that are not provided or maintained by or in any way affiliated with websites. Please note that we do not guarantee the accuracy, relevance, timeliness, or completeness of any information on these external websites.</p>
      <p className='disclaimer-text-two'>Testimonials Disclaimer</p>
      <p className='disclaimer-text-one'>The testimonials on this website are submitted in various forms such as text, audio, and/or video, and are reviewed for authenticity before being approved for posting. The testimonials reflect the real-life experiences and opinions of individuals who have used our products and/or services. However, the experiences are personal to those particular individuals and may not necessarily be representative of all users of our products and/or services. We do not claim, and you should not assume, that all users will have the same experiences. Your individual results may vary.</p>
      <p className='disclaimer-text-two'>Changes to the Disclaimer</p>
      <p className='disclaimer-text-one'>We reserve the right to update, change, or replace any part of this disclaimer at any time and for any reason without prior notice. Your continued use of or access to the website following the posting of any changes to this disclaimer constitutes acceptance of those changes.</p>
    </div>
  )
}
