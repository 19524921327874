import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { database } from './firebaseConfig'; // Adjust the path according to your file structure
import { ref, set, push } from 'firebase/database';
import './Footer.css';

const Footer = () => {
    const navigate = useNavigate(); 
    const [email, setEmail] = useState('');
    const [subscribedEmails, setSubscribedEmails] = useState([]);

    const handlePrivacy = () => {
        navigate('/privacy');
    }
    const handleConditions = () => {
        navigate('/Condition'); 
    }
    const handleDisclaimer = () => {
        navigate('/Disclaimer'); 
    }

    // Function to handle subscribing to the newsletter
    const handleSubscribe = () => {
        if (email) {
            const updatedEmails = [...subscribedEmails, email];
            setSubscribedEmails(updatedEmails);
            // Save the updated list to localStorage
            localStorage.setItem('subscribedEmails', JSON.stringify(updatedEmails));
            // Clear the input field after subscribing
            setEmail('');
            // console.log("Subscribed Emails List:", updatedEmails);

            // Save to Firebase if necessary
            const emailRef = push(ref(database, 'User EmailId/')); // Using timestamp as unique key
            set(emailRef, { email: email })
                .then(() => {
                    alert('Email saved successfully');
                })
                .catch((error) => {
                    console.error('Error saving email to Firebase:', error);
                });
        } else {
            alert('Please enter a valid email address.');
        }
    };

    useEffect(() => {
        const storedEmails = localStorage.getItem('subscribedEmails');
        if (storedEmails) {
            const parsedEmails = JSON.parse(storedEmails);
            setSubscribedEmails(parsedEmails);
            // console.log("Previously Subscribed Emails:", parsedEmails);
        }
    }, []);

    return (
        <>
            <footer className="footer">
                <div className="footer-content">
                    <div className="footer-column">
                        <div className="footer-description">
                            <p>
                                The website is dedicated to showcasing the life, teachings, and events of Acharya Rajendraprasad Shastriji, 
                                who has touched the lives of millions with his spiritual wisdom and divine presence. 
                            </p>
                        </div>
                    </div>

                    <div className="footer-column">
                        <h3>For Daily Updates</h3>
                        <div className="footer-subscribe">
                            <div className="subscribe-row">
                                <input 
                                    type="email" 
                                    placeholder="Email" 
                                    value={email} 
                                    onChange={(e) => setEmail(e.target.value)} 
                                />
                                <button onClick={handleSubscribe}>SUBSCRIBE NOW</button>
                            </div>
                        </div>
                    </div>

                    <div className="footer-column">
                        <div className="footer-topics">
                            <h3>Social Media</h3>
                            <div className="footer-socials">
                                <a href="https://x.com/RPShastriji" target="_blank" rel="noopener noreferrer" className='twitter'> 
                                    <i className="fab fa-twitter"></i> 
                                </a>
                                <a href="https://www.youtube.com/channel/UCGkJjn3nxW0aI1sJ8idTpnQ" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-youtube"></i> 
                                </a>
                                <a href="https://www.instagram.com/rajendraprashadshashtriji/" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-instagram"></i> 
                                </a>
                                <a href="https://www.linkedin.com/company/105158470/admin/dashboard/" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-linkedin"></i> 
                                </a>
                                <a href="https://www.facebook.com/profile.php?id=61565614320568" target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-facebook"></i> 
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className='footer-two'>
                <p className='copyright-text'>
                    Copyright ©2024 Rajendraprasad Shastriji.
                </p>
                <div className='footer-nav'>
                    <ul>
                        <li><a href='' onClick={handlePrivacy}>Privacy Policy</a></li>
                        <li><a href='' onClick={handleConditions}>Terms & Condition</a></li>
                        <li><a href='' onClick={handleDisclaimer}>Disclaimer</a></li>
                    </ul>
                </div>
            </div>
        </>
    );
}

export default Footer;
