import React, { useEffect, useState } from 'react';
import './Banner.css';
import photo7 from './assests/assests-new/photo7-removebg.png';
import photo11 from './assests/assests-new/photo11-removebg.png';
import photo25 from './assests/assests-new/photo25-removebg.png';
import photo26 from './assests/assests-new/photo26-removebg.png';

const banners = [
    {
        title: "Discover the Essence of Hinduism",
        description: "Delve into the rich spiritual heritage of Hinduism with Acharya Rajendraprashad Shastriji, illuminating the path to spiritual fulfillment.",
        image: photo7
    },
    {
        title: "Experience Spiritual Enlightenment",
        description: "Join Acharya Rajendraprashad Shastriji on a journey to unlock the secrets of spiritual growth and inner peace.",
        image: photo11
    },
    {
        title: "Connect with Your Inner Self",
        description: "Explore the teachings of Hinduism and find harmony within through guided practices and wisdom.",
        image: photo25
    },
    {
        title: "Transform Your Spiritual Journey",
        description: "Embark on a transformative path with Acharya Rajendraprashad Shastriji, discovering the true essence of life.",
        image: photo26
    },
];

export default function Banner() {
    const [currentBannerIndex, setCurrentBannerIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentBannerIndex((prevIndex) =>
                prevIndex === banners.length - 1 ? 0 : prevIndex + 1
            );
        }, 5000); // Change banner every 5 seconds

        return () => clearInterval(interval); 
    }, []);

    const currentBanner = banners[currentBannerIndex];

    return (
        <div className='banner'>
            <div className='banner-one'>
                <div className='banner-text'>
                    <h1 className='text-one'>
                        <b>{currentBanner.title}</b>
                    </h1>
                    <p className='text-two'>{currentBanner.description}</p>
                    {/* <button className='banner-btn'>LEARN MORE</button> */}
                </div>
                {currentBanner.image && (
                    <div className='banner-image'>
                        <img src={currentBanner.image} alt='banner' />
                    </div>
                )}
            </div>
        </div>
    );
}


