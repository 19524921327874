import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Video.css';

const Videos = () => {
  const [shorts, setShorts] = useState([]);
  const [longVideos, setLongVideos] = useState([]);
  const [showShorts, setShowShorts] = useState(true); // State to toggle between shorts and long videos
  const [loadingVideos, setLoadingVideos] = useState({}); // State to track loading status for each video
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const shortsPerPage = 8; // Shorts per page
  const longVideosPerPage = 6; // Long videos per page
  const API_KEY = 'AIzaSyC-s6quFXabGlKnfIRDxG3Fc-YoV3ZAGrA';
  const CHANNEL_ID = 'UCGkJjn3nxW0aI1sJ8idTpnQ';

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        let shorts = [];
        let longVideos = [];
        let nextPageToken = '';
        
        // Fetch Shorts with pagination
        do {
          const shortsResponse = await axios.get(
            `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&part=snippet&type=video&maxResults=50&videoDuration=short&order=date&pageToken=${nextPageToken}`
          );
          shorts = [...shorts, ...shortsResponse.data.items];
          nextPageToken = shortsResponse.data.nextPageToken || '';
        } while (nextPageToken);
  
        setShorts(shorts);
        
        // Reset nextPageToken for long videos
        nextPageToken = '';
  
        // Fetch Long Videos with pagination
        do {
          const longResponse = await axios.get(
            `https://www.googleapis.com/youtube/v3/search?key=${API_KEY}&channelId=${CHANNEL_ID}&part=snippet&type=video&maxResults=50&videoDuration=long&order=date&pageToken=${nextPageToken}`
          );
          longVideos = [...longVideos, ...longResponse.data.items];
          nextPageToken = longResponse.data.nextPageToken || '';
        } while (nextPageToken);
  
        setLongVideos(longVideos);
  
        // Set loading state for each video to true initially
        const initialLoadingState = {};
        shorts.forEach((video) => {
          initialLoadingState[video.id.videoId] = true;
        });
        longVideos.forEach((video) => {
          initialLoadingState[video.id.videoId] = true;
        });
        setLoadingVideos(initialLoadingState);
      } catch (error) {
        console.error('Error fetching videos:', error);
      }
    };
  
    fetchVideos();
  }, []);
  

  const handleVideoLoad = (videoId) => {
    setLoadingVideos((prevState) => ({
      ...prevState,
      [videoId]: false, // Set the loading state to false once the video is loaded
    }));
  };

  // Pagination logic
  const indexOfLastVideo = currentPage * (showShorts ? shortsPerPage : longVideosPerPage);
  const indexOfFirstVideo = indexOfLastVideo - (showShorts ? shortsPerPage : longVideosPerPage);
  const currentVideos = showShorts
    ? shorts.slice(indexOfFirstVideo, indexOfLastVideo)
    : longVideos.slice(indexOfFirstVideo, indexOfLastVideo);

  const totalPages = Math.ceil(
    (showShorts ? shorts.length : longVideos.length) / (showShorts ? shortsPerPage : longVideosPerPage)
  );

  // Event handlers for pagination
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      {/* Buttons to toggle between Shorts and Long Videos */}
      <div className="video-toggle-buttons">
        <button onClick={() => { setShowShorts(true); setCurrentPage(1); }} className={showShorts ? 'active' : ''}>
          Shorts
        </button>
        <button onClick={() => { setShowShorts(false); setCurrentPage(1); }} className={!showShorts ? 'active' : ''}>
          Videos
        </button>
      </div>

      {/* Conditionally render the YouTube Shorts or Long Videos section based on the state */}
      {showShorts ? (
        <section className="youtube-shorts">
          <h3>Shorts</h3>
          <ul className="youtube-video">
            {currentVideos.map((video) => (
              <li className='shorts-video' key={video.id.videoId}>
                <h4>{video.snippet.title}</h4>
                {loadingVideos[video.id.videoId] ? (
                  <div className="spinner">
                    {/* Spinner for individual shorts */}
                    <div className="loading-spinner"></div>
                  </div>
                ) : null}
                <iframe
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${video.id.videoId}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title={video.snippet.title}
                  onLoad={() => handleVideoLoad(video.id.videoId)}
                ></iframe>
              </li>
            ))}
          </ul>
        </section>
      ) : (
        <section className="youtube-long-videos">
          <h3>Videos</h3>
          <ul className="youtube-video-extra">
            {currentVideos.map((video) => (
              <li className='long-video' key={video.id.videoId}>
                <h4>{video.snippet.title}</h4>
                {loadingVideos[video.id.videoId] ? (
                  <div className="spinner">
                    <div className="loading-spinner"></div>
                  </div>
                ) : null}
                <iframe
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${video.id.videoId}`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title={video.snippet.title}
                  onLoad={() => handleVideoLoad(video.id.videoId)}
                ></iframe>
              </li>
            ))}
          </ul>
        </section>
      )}

      {/* Pagination Controls */}
      <div className="pagination">
        <button onClick={goToPreviousPage} disabled={currentPage === 1}>
          Previous
        </button>

        {/* Display the current page number */}
        <span className="current-page">Page {currentPage}  of {totalPages}</span>

        <button onClick={goToNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </div>
  );
};

export default Videos;
