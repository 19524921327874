import React from 'react';
import './Contact.css'
import photo26 from './assests/photo26.jpeg'
export default function Contact() {
  return (
    <div className='contact'>
        <div className='contact-image'>
          <img src={photo26} alt='contact-img' className='contact-img'></img>
        </div>
        <div className='contact-us'>
            <h1>Contact us</h1>
        </div>
        <div className='contact-text'>
            <p>For More Information or any queries contact: +91 9725005495</p><br/>
            <p>Bala Hanumanji, Seva Sansthan, Sher Patiya, Mandal - Dalod Road, Mandal
            Taluka - Mandal
            District - Ahmedabad</p>
        </div>
        
    </div>
  )
}
