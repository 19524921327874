// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    databaseURL: "https://rp-shastriji-default-rtdb.firebaseio.com/",
    appId: "1:1091439311103:web:774b1936a47fb3a5f9b4ca"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database };
