import React from 'react';
import './Modal.css';

export default function Modal({ isOpen, imageSrc, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="modal" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="close-button" onClick={onClose}>&times;</span>
        <img className="modal-image" src={imageSrc} alt="Enlarged view" />
      </div>
    </div>
  );
}
