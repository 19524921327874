import React from 'react';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import photo15 from '../component/assests/assests-new/photo15.jpeg';
import { FaBars, FaTimes } from 'react-icons/fa';

export default function Header() {
    const navigate = useNavigate(); 
    const [showDropdown, setShowDropdown] = React.useState(false);
    const [isMobileMenuOpen, setMobileMenuOpen] = React.useState(false); 

    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };
  
    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen); 
    };

    const trackPageView = (pagePath) => {
        if (window.gtag) {
            window.gtag('config', 'G-EWMP9BYX7W', {
                page_path: pagePath
            });
        }
    };

    const handleNavigationClick = (pagePath) => {
        navigate(pagePath);
        setMobileMenuOpen(false);
        trackPageView(pagePath); // Track page view with GA4
    };

    return (
        <>
            <nav className='nav-bar'>
                <div className='nav-logo'>
                    <img src={photo15} alt='nav' className='logo' />
                </div>
                <div className='mobile-menu-icon' onClick={toggleMobileMenu}>
                    {isMobileMenuOpen ? <FaTimes /> : <FaBars />} 
                </div>

                {/* Show this on larger screens */}
                <ul className='navbar-items'>
                    <li><a href='/' onClick={() => handleNavigationClick('/')}>Home</a></li>
                    <li><a href='/about' onClick={() => handleNavigationClick('/about')}>About</a></li>
                    <li>
                        <a href="/video" onClick={toggleDropdown} className={showDropdown ? 'show-dropdown' : ''}>
                            Videos 
                        </a>
                    </li>
                    <li><a href='/gallery' onClick={() => handleNavigationClick('/gallery')}>Gallery</a></li>
                    <li><a href='/contact' onClick={() => handleNavigationClick('/contact')}>Contact</a></li>
                </ul>
            </nav>

            {/* Sidebar for mobile navigation */}
            <div className={`sidebar ${isMobileMenuOpen ? 'active' : ''}`}>
                <ul>
                    <li><a href='/' onClick={() => handleNavigationClick('/')}>Home</a></li>
                    <li><a href='/about' onClick={() => handleNavigationClick('/about')}>About</a></li>
                    <li>
                        <a href="/video" onClick={toggleDropdown} className={showDropdown ? 'show-dropdown' : ''}>
                            Videos 
                        </a>
                    </li>
                    <li><a href='/gallery' onClick={() => handleNavigationClick('/gallery')}>Gallery</a></li>
                    <li><a href='/contact' onClick={() => handleNavigationClick('/contact')}>Contact</a></li>
                </ul>
            </div>

            {/* Overlay for dimming the background */}
            {isMobileMenuOpen && <div className='sidebar-overlay' onClick={toggleMobileMenu}></div>}
        </>
    );
}