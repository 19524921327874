import React from 'react'
import './Privacy.css'
export default function Privacy() {
  return (
    <div className='privacy'>
        <h1 className='privacy-text-one'>Privacy Policy</h1>
        <p className='line-one'>
        This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from our websites.
        </p>
        <h4 className='privacy-text-two'>Personal Information We Collect</h4>
        <p className='line-one'>When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”</p>
        <h4 className='privacy-text-three'>Sharing Your Personal Information</h4>
        <p>
          We share your Personal Information with third parties to help us use your Personal Information, as described above. For example, we use Shopify to power our online store–you can read more about how Shopify uses your Personal Information here: <a href="https://www.shopify.com/legal/privacy" target="_blank">https://www.shopify.com/legal/privacy</a>. We also use Google Analytics to help us understand how our customers use the Site – you can read more about how Google uses your Personal Information here: <a href="https://www.google.com/intl/en/policies/privacy/" target="_blank">https://www.google.com/intl/en/policies/privacy/</a>. You can also opt-out of Google Analytics here: <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a>.
        </p>
    </div>
  )
}
