import React, { useState } from 'react';
import './Gallery.css';
import Modal from './Modal';
import Masonry from 'react-masonry-css'; // Import Masonry component

// Dynamically import all images from the assets folder
const importAll = (requireContext) => requireContext.keys().map(requireContext);
const photos = importAll(require.context('./assests', false, /\.(jpeg|jpg|png)$/));

export default function Gallery() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [imageLoading, setImageLoading] = useState(new Array(photos.length).fill(true)); // Track loading for each image

  const itemsPerPage = 12; // Number of items to display per page
  const totalPages = Math.ceil(photos.length / itemsPerPage); // Total pages calculation

  // Calculate the index of the first and last items to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Get current items to display
  const currentItems = photos.slice(indexOfFirstItem, indexOfLastItem);

  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };

  const handleImageLoad = (index) => {
    const newLoadingState = [...imageLoading];
    newLoadingState[index] = false; // Set the specific image as loaded
    setImageLoading(newLoadingState);
  };

  return (
    <div className='gallery'>
      <h1 className='gallery-text'>GALLERY</h1>
      <Masonry
        breakpointCols={{ default: 3, 800: 2, 500: 1 }} 
        className='photo-gallery'
        columnClassName='column'
      >
        {currentItems.map((photo, index) => (
          <div className='photo' key={index} onClick={() => openModal(photo)}>
            {imageLoading[index + indexOfFirstItem] && (
              <div className="loading-spinner-gallery">Loading...</div>
            )}
            <img 
              src={photo} 
              alt={`Gallery item ${index + indexOfFirstItem + 1}`} 
              style={{ display: imageLoading[index + indexOfFirstItem] ? 'none' : 'block' }}
              onLoad={() => handleImageLoad(index + indexOfFirstItem)} // Handle load event
              onError={() => handleImageLoad(index + indexOfFirstItem)} // Handle error event, to stop loading in case of error
            />
          </div>
        ))}
      </Masonry>
      <div className='pagination'>
        <button 
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button 
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
      <Modal isOpen={isModalOpen} imageSrc={selectedImage} onClose={closeModal} />
    </div>
  );
}
